import { get, post, downFile } from '@/common/request'

export default {
  getFileList (params) {
    return get('/attachFile/list', params)
  },

  getFileDetail (id) {
    return get('/attachFile/get', id)
  },
  uploadFile (params) {
    return post('/attachFile/localAndLibraryUpload', params)
  },
  downFlie (params) {
    return downFile('/file/download', params)
  },

  deleteFile (params) {
    return get('/attachFile/deleteFile', params)
  },

  fileClassList: args => get('/lbrary/lbraryTreeList', args),

  fileList: args => get('/attachFile/list', args),

  getFileDetailList: args => get(`/attachFile/queryTblAttachFileListByParameter?${args}`),

  dictData: args => get('/dict-data/getByDicKind', args),

  deptList: args => get('/dept/queryList', args)
}
